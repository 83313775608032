import React from 'react';
import NextError from 'next/error';
import Err404 from '@/components/common/404';

class ErrorPage extends React.Component {
  render() {
    if (this.props.statusCode === 404) {
      return <Err404 />;
    }
    return (
      <>
        <NextError
          statusCode={this.props.statusCode}
          title={`An error ${this.props.statusCode} occurred on server`}
        />
      </>
    );
  }
}

ErrorPage.getInitialProps = ({ res, err }) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
  return { statusCode };
};

export default ErrorPage;
